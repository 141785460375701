import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Buda from "../images/cursos/Buda.webp";

const Budas = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Convertirnos en Budas"
        phrase="Despertar a una vida plenamente consciente."
        time="10 Horas"
        class="8 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Buda}
        description="A través de la comprensión de las 6 perfecciones budistas, comprenderemos el objetivo final del budismo, que es convertirnos en seres despiertos, libres y altruistas."
        list={[
          "Introducción a las 6 perfecciones: ¿qué es despertar y liberarse?",
          "Generosidad",
          "Conducta ética",
          "Paciencia",
          "Esfuerzo gozoso",
          "Meditación/concentración",
          " Sabiduría",
        ]}
        duracion="8 sesiones de entre 60 y 75 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="10%"
        imgWidth="20%"
        right="5%"
        topMob="20%"
        rightMob="5%"
        imgWidthMob="33%"
      />
    </Layout>
  ) : null;
};

export default Budas;
